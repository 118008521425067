import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "what-is-runtime-mode"
    }}>{`What is Runtime mode`}</h2>
    <p>{`Runtime mode allows Stele to perform translation lookups at runtime as opposed
to compile time.`}</p>
    <p>{`This is necessary because sometimes the message to be translated is context
dependent and the context is only known at runtime.`}</p>
    <p>{`Stele achieves this by transforming the message to include an IIFE containing a
switch statement of the different locales demonstrated:`}</p>
    <p>{`Input JSX:`}</p>
    <pre><code parentName="pre" {...{}}>{`<Text i18n={locale}>hello</Text>
`}</code></pre>
    <p>{`Output JSX:`}</p>
    <pre><code parentName="pre" {...{}}>{`(function (_locale) {
    switch (_locale) {
      default:
        return <Text>hello</Text>;

      case "fr-FR":
        return <Text>bonjour</Text>;

      case "de-DE":
        return <Text>guten tag</Text>;
    }
  })(locale)
`}</code></pre>
    <hr></hr>
    <p>{`Input translate function:`}</p>
    <pre><code parentName="pre" {...{}}>{`import { translate } from '@patreon/stele'

translate(locale, 'hello friend')
`}</code></pre>
    <p>{`Output translate function:`}</p>
    <pre><code parentName="pre" {...{}}>{`translate(locale, (function (_locale) {
    switch (_locale) {
      default:
        return "hello friend";

      case "de-DE":
        return "Hallo Freund";

      case "fr-FR":
        return "salut l'ami";
    }
  })(locale))
`}</code></pre>
    <h2 {...{
      "id": "how-to-enable-runtime-mode"
    }}>{`How to enable Runtime Mode`}</h2>
    <p>{`To enable runtime mode in your `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{`, you will want your config for Stele
to look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Locale } from '@patreon/stele'
;[
    '@patreon/stele/dist/plugin',
    {
        targetLocales: [Locale('en-US'), Locale('fr-FR'), Locale('de-DE')],
        runtimeInlineTranslations: true,
    },
]
`}</code></pre>
    <p>{`Note: `}<inlineCode parentName="p">{`targetLocales`}</inlineCode>{` is mutually exclusive with compile time's `}<inlineCode parentName="p">{`currentLocale`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      